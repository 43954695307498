<template>
  <div class="p-2">
    <div class="flex flex-wrap -mx-2">
      <label class="block w-full md:w-1/2 mt-4 px-2">
        <span class="text-sm text-gray-800 font-semibold">Erstzulassung *</span>
        <input
          type="date"
          class="form-input w-full mt-1"
          v-model="registration"
          @blur="validateDateInput(registration, 'registration')"
          :placeholder="
            currentStepPath.registration ? currentStepPath.registration : 'Datum eingeben'
          "
        />
        <p v-if="!registrationValidated" class="mt-4 text-xs text-red-500 whitespace-pre-line">
          {{ dateValidateError }}
        </p>
      </label>

      <label class="block w-full md:w-1/2 mt-3 px-2">
        <span class="text-sm text-gray-800 font-semibold">Kilometerstand bei Übergabe *</span>
        <input
          type="number"
          class="form-input w-full mt-1"
          min="10"
          v-model="currentStepPath.mileage"
          required
          inputmode="numeric"
        />
      </label>
      <label class="block w-full md:w-1/2 mt-4 px-2">
        <input
          type="checkbox"
          class="form-checkbox p-3 mr-2"
          v-model="currentStepPath.availability"
        />
        <span class="text-sm text-gray-800 font-semibold">Fahrzeug sofort verfügbar</span>
        <div :class="['text-xs mt-2', currentStepPath.availability ? 'text-gray-400' : '']">
          Verfügbar ab:
        </div>
        <input
          type="date"
          class="form-input w-full mt-1"
          v-model="availableFrom"
          @blur="validateDateInput(availableFrom, 'availableFrom')"
          :disabled="currentStepPath.availability"
          :required="!currentStepPath.availability"
          :placeholder="
            currentStepPath.availableFrom ? currentStepPath.availableFrom : 'Datum auswählen'
          "
        />
        <p v-if="!availableFromValidated" class="mt-4 text-xs text-red-500 whitespace-pre-line">
          {{ dateValidateError }}
        </p>
      </label>
    </div>
    <div>
      <p
        v-if="schmitzStore.vehicleSelectionError"
        class="mt-4 text-xs text-red-500 text-right whitespace-pre-line"
      >
        {{ schmitzStore.vehicleSelectionError }}
      </p>
      <div class="flex justify-end py-4">
        <button
          @click="schmitzStore.goBack()"
          class="flex mt-2 items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
        >
          <svg class="arrow-icons mx-2">
            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
          </svg>
          Zurück
        </button>
        <button
          class="mt-4 ml-4 flex items-center bg-blue-600 text-white font-semibold text-xl px-4 py-1 border rounded shadow-md hover:bg-blue-800 disabled:opacity-50"
          @click.self="schmitzStore.nextSelection()"
        >
          Weiter
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSchmitzStore } from '@/stores/schmitzStore'
import { computed, onMounted, ref, watch } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const schmitzStore = useSchmitzStore()
const currentStepPath = computed(() => {
  return schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[schmitzStore.currentStepKey]
    .selectedOption
})

const registration = ref('')
const availableFrom = ref('')
let dateValidateError = ref('')
let registrationValidated = ref(true)
let availableFromValidated = ref(true)

function validateDateInput(value, type) {
  const regex = /^\d{4}\-\d{1,2}\-\d{1,2}$/ // matches DD.MM.YYYY format
  if (!regex.test(value)) {
    dateValidateError = ' Format des Datums muss DD.MM.YYYY sein'
    if (type === 'registration') {
      registrationValidated.value = false
      registration.value = ''
    } else if (type === 'availableFrom') {
      availableFromValidated.value = false
      availableFrom.value = ''
    }
  } else {
    const year = parseInt(value.split('-')[0], 10) // extract the year from the input value
    const currentYear = new Date().getFullYear()
    if (year < 2017 || year > currentYear) {
      // year is less than 2017, reset the input value
      dateValidateError = `Baujahr muss zwischen 2017 und ${currentYear} sein`
      if (type === 'registration') {
        registrationValidated.value = false
        registration.value = ''
      } else if (type === 'availableFrom') {
        availableFromValidated.value = false
        availableFrom.value = ''
      }
    } else {
      if (type === 'registration') {
        registrationValidated.value = true
      } else if (type === 'availableFrom') {
        availableFromValidated.value = true
      }
    }
  }
}

watch(
  () => currentStepPath.value.availability,
  (newValue) => {
    if (newValue === true) {
      availableFrom.value = '' // set availableFrom to an empty string or a default value
    }
  }
)
watch(registration, (newValue) => {
  currentStepPath.value.registration = newValue
  console.log(newValue)
})
watch(availableFrom, (newValue) => {
  currentStepPath.value.availableFrom = newValue
})

onMounted(async () => {
  // console.log(JSON.stringify(tradeInStore.selectedVehicle, null, 2))
  schmitzStore.accordion[schmitzStore.currentExpandedKey].steps[
    schmitzStore.currentStepKey
  ].visited = true
})
</script>
