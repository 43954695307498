import { defineStore } from 'pinia'
import { nextTick, reactive } from 'vue'

export const useSchmitzStore = defineStore('schmitzStore', {
  state: () => {
    return {
      currentStepKey: null,
      currentExpandedKey: null,
      previousStepKey: null,
      previousAccordionKey: null,
      nextAccordionKey: null,
      nextStepKey: null,
      nextStep: null,
      vehicleSelectionError: null,
      allStepsSelected: Boolean,
      months: {
        '01': 'Januar',
        '02': 'Februar',
        '03': 'März',
        '04': 'April',
        '05': 'Mai',
        '06': 'Juni',
        '07': 'Juli',
        '08': 'August',
        '09': 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Dezember'
      },
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      requestData: {},
      responseData: {},
      responseDataCheck: false,
      requestError: null,
      error: null,
      loading: reactive(false),
      accordion: reactive({
        accordion1: {
          title: 'Modell',
          content: 'model',
          steps: {
            model: {
              title: 'Modell',
              selectedOption: {},
              selected: false,
              options: [
                { value: 'Model3', label: 'Model 3' },
                { value: 'ModelY', label: 'Model Y' }
              ]
            },
            modelVariant: {
              title: 'Variante',
              selectedOption: {},
              selected: false,
              options: [
                // Is defined in generateModelVariantOptions()
              ]
            },
            // this step is restricted by the selectedOption() case modelVariant
            driveType: {
              title: 'Antrieb',
              selectedOption: {},
              selected: false,
              options: [
                { value: 'AWD', label: 'Allrad(AWD)' },
                { value: 'RWD', label: 'Hinterradantrieb(RWD)' }
              ]
            }
          }
        },
        accordion2: {
          title: 'Fahrzeugdaten',
          content: 'carRegistration',
          steps: {
            carRegistration: {
              selectedOption: { mileage: 0, availability: true },
              selected: false,
              visited: false
            }
          }
        },
        accordion3: {
          title: 'Außen- und Innenfarbe',
          content: 'colors',
          steps: {
            bodyColor: {
              title: 'Karosseriefarbe',
              selectedOption: {},
              selected: false,
              options: [
                { value: 'white', label: 'Weiß' },
                { value: 'silver', label: 'Silber' },
                { value: 'black', label: 'Schwarz' },
                { value: 'gray', label: 'Grau' },
                { value: 'blue', label: 'Blau' },
                { value: 'red', label: 'Rot' }
              ]
            },
            interiorColor: {
              title: 'Innenfarbe',
              selectedOption: {},
              selected: false,
              options: [
                { value: 'black', label: 'Schwarz' },
                { value: 'white', label: 'Weiß' }
              ]
            },
            // this step is restricted by the selectedOption() case modelVariant
            rimColor: {
              title: 'Farbe der Felgen',
              selectedOption: {},
              selected: false,
              options: [
                { value: 'black', label: 'Schwarz' },
                { value: 'white', label: 'Weiß' }
              ]
            }
          }
        },
        accordion4: {
          title: 'Sonderausstattung',
          content: 'equipment',
          steps: {
            autopilot: {
              title: 'Autonomes Fahren',
              selectedOption: { value: 'StandardAutopilot', label: 'Autopilot (Standard)' },
              selected: false,
              options: [
                { value: 'StandardAutopilot', label: 'Autopilot (Standard)' },
                { value: 'ExtendedAutopilot', label: 'Erweiterter Autopilot (EAP)' },
                { value: 'FullServiceDrive', label: 'volles Potenzial für autonomes Fahren (FSD)' },
                { value: 'NoAutopilot', label: 'Kein Autopilot' }
              ]
            },
            rims: {
              title: 'Felgen',
              selectedOption: {
                value: 'OriginalRims',
                label: 'Original Felgen'
              },
              selected: false,
              options: [
                { value: 'OriginalRims', label: 'Original Felgen' },
                { value: 'AccessoryRims', label: 'Zubehör Felgen' }
              ]
            },
            rimType: {
              title: 'Felgentypen',
              selectedOption: {},
              selected: false,
              options: [
                // is defined in generateRimTypeOptions()
              ]
            },
            customEquipment: {
              title: 'zusätzliche Ausstattung',
              selectedOption: [],
              selected: false,
              options: [
                // is defined in generateModelVariantOptions()
              ]
            }
          }
        },
        accordion5: {
          title: 'Fahrzeugzustand',
          content: 'damage',
          steps: {
            accident: {
              title: 'Unfall',
              selectedOption: { accident: false, accidentDescription: '' },
              selected: false
            },
            damage: {
              title: 'aktuelle Beschädigungen',
              selectedOption: { damage: false, damageDescription: '' },
              selected: false
            }
          }
        },
        accordion6: {
          title: 'Preis',
          content: 'priceRequest',
          steps: {
            price: {
              title: 'Preisvorstellung',
              selectedOption: { saleType: 'Privater Verkauf', mwSt: false },
              selected: false
            },
            finance: {
              title: 'Finanzierung',
              selectedOption: { financing: false },
              selected: false
            }
          }
        },
        accordion7: {
          title: 'Kostenlos & unverbindlich anfragen',
          content: 'submit',
          steps: {
            submit: {
              selectedOption: {
                firstName: '',
                lastName: '',
                email: '',
                privacyPolicy: false,
                marketing: []
              },
              selected: false,
              visited: false,
              options: [
                { value: 'Google', label: 'Google-Suche' },
                { value: 'EFIEBER', label: 'EFIEBER' },
                { value: 'Advertisment', label: 'Werbung' },
                { value: 'Returning Customer', label: 'bereits bekannt' },
                { value: 'Other', label: 'sonstiges' }
              ]
            }
          }
        }
      })
    }
  },
  watch: {},
  actions: {
    findExpandedIndex(currentStepKey) {
      Object.keys(this.accordion).forEach((accordionKey) => {
        const accordion = this.accordion[accordionKey]
        Object.keys(accordion.steps).forEach((stepKey) => {
          if (stepKey === currentStepKey) {
            this.currentExpandedKey = accordionKey
          }
        })
      })
      return this.currentExpandedKey
    },
    defineStepKey() {
      this.nextStep = Object.values(this.accordion).find((accordion) =>
        Object.values(accordion.steps).find((step) => !step.selected)
      )
      // console.log(this.accordion)
      if (this.nextStep) {
        const stepKeys = Object.keys(this.nextStep.steps)
        for (const stepKey of stepKeys) {
          if (!this.nextStep.steps[stepKey].selected) {
            this.currentStepKey = stepKey
            this.currentExpandedKey = this.findExpandedIndex(this.currentStepKey)
            // console.log(this.currentStepKey)
            // console.log(this.currentExpandedKey)
            return stepKey
          }
        }
      }
      return null
    },
    toggleAccordion(index) {
      // console.log(`toggleAccordion called with index: ${index}`)
      const accordion = this.accordion[index]
      // console.log(`accordion at index ${index}:`, accordion)

      this.allStepsSelected = Object.values(accordion.steps).every((step) => step.selected)
      // console.log(`allStepsSelected: ${this.allStepsSelected}`)

      const currentStep = this.defineStepKey()
      // console.log(`currentStep: ${currentStep}`)

      let lastStepWithOptions = null
      // console.log(`lastStepWithOptions: ${lastStepWithOptions}`)

      if (this.allStepsSelected || currentStep) {
        // console.log(
        //   `setting previousAccordionKey form toggle Accordion to ${this.currentExpandedKey}`
        // )
        // console.log(`setting currentExpandedKey form toggle Accordion to ${index}`)
        this.currentExpandedKey = index

        const firstUnselectedStep = Object.values(accordion.steps).find(
          (step) => step.selected === false
        )
        // console.log(`firstUnselectedStep:`, firstUnselectedStep)

        if (!firstUnselectedStep) {
          // console.log('No unselected steps found')
          // Find the first step with selected set to false

          if (firstUnselectedStep) {
            // console.log('Finding currentStepKey for firstUnselectedStep')
            // this.currentStepKey = Object.keys(accordion.steps).find(
            //   (key) => accordion.steps[key] === firstUnselectedStep
            // )
            // console.log(`currentStepKey for firstUnselectedStep: ${this.currentStepKey}`)
          } else {
            // If no unselected steps, set currentStepKey to the first step
            // console.log('No unselected steps, setting currentStepKey to the last step')
            this.currentStepKey = Object.keys(accordion.steps)[
              Object.keys(accordion.steps).length - 1
            ]
            // console.log(`currentStepKey set to last step: ${this.currentStepKey}`)

            if (this.currentStepKey === 'rimColor') {
              // console.log('Checking rimColor selectedOption')
              if (this.accordion.accordion3.steps.rimColor.selectedOption.value === 'Standard') {
                // console.log(
                //   'rimColor selectedOption is Standard, setting currentStepKey to interiorColor'
                // )
                this.currentStepKey = 'interiorColor'
              }
            }
          }
        } else {
          // console.log('Finding currentStepKey for firstUnselectedStep')
          lastStepWithOptions = firstUnselectedStep // Set lastStepWithOptions auf firstUnselectedStep
          this.currentStepKey = Object.keys(accordion.steps).find(
            (key) => accordion.steps[key] === lastStepWithOptions
          )
          // console.log(`currentStepKey for firstUnselectedStep: ${this.currentStepKey}`)
        }
        // console.log(`currentStepKey form toggle Accordion: ${this.currentStepKey}`)

        // Update previousStepKey and previousAccordionKey
        // console.log(`previousStepKey before update: ${this.previousStepKey}`)
        // console.log(`previousAccordionKey before update: ${this.previousAccordionKey}`)
        this.previousStepKey = this.currentStepKey
        this.previousAccordionKey = this.currentExpandedKey
        // console.log(`previousStepKey after update: ${this.previousStepKey}`)
        // console.log(`previousAccordionKey after update: ${this.previousAccordionKey}`)

        // Update nextAccordionKey and nextStepKey
        const accordionKeys = Object.keys(this.accordion)
        // console.log(`accordionKeys: ${accordionKeys}`)
        const currentAccordionIndex = accordionKeys.indexOf(this.currentExpandedKey)
        // console.log(`currentAccordionIndex: ${currentAccordionIndex}`)
        if (currentAccordionIndex < accordionKeys.length - 1) {
          // console.log('Setting nextAccordionKey and nextStepKey')
          this.nextAccordionKey = accordionKeys[currentAccordionIndex + 1]
          this.nextStepKey = Object.keys(this.accordion[this.nextAccordionKey].steps)[0]
          // console.log(`nextAccordionKey: ${this.nextAccordionKey}`)
          // console.log(`nextStepKey: ${this.nextStepKey}`)
        } else {
          // console.log('No next accordion, setting nextAccordionKey and nextStepKey to null')
          this.nextAccordionKey = null
          this.nextStepKey = null
        }
      } else {
        // console.log(`not all steps selected, toggling to last selected step`)
        if (this.previousAccordionKey !== null) {
          // console.log(`toggling back to previous accordion index: ${this.previousAccordionKey}`)
          this.currentExpandedKey = this.previousAccordionKey
          this.previousAccordionKey = null
        } else {
          const currentStepKey = this.defineStepKey()
          // console.log(`currentStepKey form toggle Accordion: ${currentStepKey}`)
          if (currentStepKey) {
            // console.log(`finding expanded index for current step key`)
            this.currentExpandedKey = this.findExpandedIndex(currentStepKey)
          }
        }
      }
      // console.log(`final currentExpandedKey: ${this.currentExpandedKey}`)
      // console.log(`final previousAccordionKey: ${this.previousAccordionKey}`)
    },
    async goForward() {
      // console.log('goForward')

      if (this.nextAccordionKey !== null && this.nextStepKey !== null) {
        // console.log('nextAccordionKey und nextStepKey sind nicht null')
        this.previousAccordionKey = this.currentExpandedKey
        this.previousStepKey = this.currentStepKey

        this.currentExpandedKey = this.nextAccordionKey
        this.currentStepKey = this.nextStepKey
        await nextTick()

        // console.log('Aktueller Accordion-Key:', this.currentExpandedKey)
        // console.log('Aktueller Step-Key:', this.currentStepKey)
        if (this.currentStepKey === 'carRegistration') {
          if (this.accordion.accordion3.steps.rimColor.selected === false) {
            this.defineStepKey()
          }
        }
        if (this.currentStepKey === 'rimColor') {
          if (
            this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selectedOption
              .value === 'Standard'
          ) {
            this.goForward()
          }
        }
        if (this.currentStepKey === 'rims') {
          this.goForward()
        }

        // Find the Index of the current step
        const currentStepIndex = Object.keys(this.accordion[this.currentExpandedKey].steps).indexOf(
          this.currentStepKey
        )
        // console.log('Aktueller Step-Index:', currentStepIndex)

        // Find the Index of the current accordion
        const accordionKeys = Object.keys(this.accordion)
        const currentAccordionIndex = accordionKeys.indexOf(this.currentExpandedKey)
        // console.log('Aktueller Accordion-Index:', currentAccordionIndex)

        // if current step is not the last step, update nextAccordionKey and nextStepKey
        if (
          currentStepIndex <
          Object.keys(this.accordion[this.currentExpandedKey].steps).length - 1
        ) {
          // console.log('Aktueller Step ist nicht der letzte Step')
          const nextStepKey = Object.keys(this.accordion[this.currentExpandedKey].steps)[
            currentStepIndex + 1
          ]
          // console.log('Nächster Step-Key:', nextStepKey)

          // Verify if last step is selected
          if (!this.accordion[this.currentExpandedKey].steps[nextStepKey].selected) {
            // console.log('Nächster Step ist nicht ausgewählt')
            this.nextAccordionKey = null
            this.nextStepKey = null
          } else {
            // console.log('Nächster Step ist ausgewählt')
            this.nextAccordionKey = this.currentExpandedKey
            this.nextStepKey = nextStepKey
          }
        } else if (currentAccordionIndex < accordionKeys.length - 1) {
          // console.log('Aktueller Accordion ist nicht der letzte Accordion')
          // If the current step is the last step and the current accordion is the last accordion,
          // set nextAccordionKey to the key of the next accordion
          const nextAccordionKey = accordionKeys[currentAccordionIndex + 1]
          // console.log('Nächster Accordion-Key:', nextAccordionKey)
          this.nextAccordionKey = nextAccordionKey
          this.nextStepKey = Object.keys(this.accordion[nextAccordionKey].steps)[0]
        } else {
          // console.log(
          //   'Aktueller Step ist der letzte Step und aktueller Accordion ist der letzte Accordion'
          // )
          // if the current Step is the last step and the current accordion is the last accordion,
          // set nextAccordionKey and nextStepKey null
          this.nextAccordionKey = null
          this.nextStepKey = null
        }
      } else {
        // console.log('nextAccordionKey oder nextStepKey sind null')
      }
    },
    async goBack() {
      if (this.previousAccordionKey !== null && this.previousStepKey !== null) {
        this.nextAccordionKey = this.currentExpandedKey
        this.nextStepKey = this.currentStepKey
        if (
          this.accordion[this.previousAccordionKey].steps[this.previousStepKey].proposals
            ?.length === 1
        ) {
          // find the previous step with more than one proposal
          const previousSteps = Object.keys(this.accordion[this.previousAccordionKey].steps)
          const currentIndex = previousSteps.indexOf(this.previousStepKey)
          for (let i = currentIndex - 1; i >= 0; i--) {
            if (
              this.accordion[this.previousAccordionKey].steps[previousSteps[i]].proposals.length > 1
            ) {
              this.previousStepKey = previousSteps[i]
              break
            }
          }
          // If not previous step with one proposal is found, got to previous accordion
          if (this.previousStepKey === this.currentStepKey) {
            const previousAccordionKey = accordionKeys[currentAccordionIndex - 1]
            this.previousAccordionKey = previousAccordionKey
            this.previousStepKey = Object.keys(this.accordion[previousAccordionKey].steps)[
              Object.keys(this.accordion[previousAccordionKey].steps).length - 1
            ]
          }
        }
        this.currentExpandedKey = this.previousAccordionKey
        this.currentStepKey = this.previousStepKey
        await nextTick()
        // console.log(this.accordion)
        if (this.currentStepKey === 'rimColor') {
          if (
            this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selectedOption
              .value === 'Standard'
          ) {
            this.goBack()
          }
        }
        if (this.currentStepKey === 'rimType') {
          this.goBack()
        }
        // Find the index of the current step
        const currentStepIndex = Object.keys(this.accordion[this.currentExpandedKey].steps).indexOf(
          this.currentStepKey
        )

        // Find the index of the current accordion
        const accordionKeys = Object.keys(this.accordion)
        const currentAccordionIndex = accordionKeys.indexOf(this.currentExpandedKey)

        // If the current step is not the first step, update previousAccordionKey and previousStepKey
        if (currentStepIndex > 0) {
          const previousStepKey = Object.keys(this.accordion[this.currentExpandedKey].steps)[
            currentStepIndex - 1
          ]

          this.previousAccordionKey = this.currentExpandedKey
          this.previousStepKey = previousStepKey
        } else if (currentAccordionIndex > 0) {
          // If the current step is the first step and the current accordion is not the first accordion,
          // set previousAccordionKey to the key of the previous accordion
          const previousAccordionKey = accordionKeys[currentAccordionIndex - 1]
          this.previousAccordionKey = previousAccordionKey
          this.previousStepKey = Object.keys(this.accordion[previousAccordionKey].steps)[
            Object.keys(this.accordion[previousAccordionKey].steps).length - 1
          ]
        } else {
          // If the current step is the first step and the current accordion is the first accordion,
          // set previousAccordionKey and previousStepKey to null
          this.previousAccordionKey = null
          this.previousStepKey = null
        }
      } else {
        // Handle the case where the user is on the first step
        // console.log('You are already on the first step')
      }
      // console.log(this.currentStepKey)
      // console.log(this.previousStepKey)
    },
    async nextSelection() {
      // Save the current step and accordion keys as previous keys

      this.previousStepKey = this.currentStepKey
      this.previousAccordionKey = this.currentExpandedKey

      // console.log('after first define', this.currentStepKey)

      let stepKey = this.currentStepKey
      let step = this.accordion[this.currentExpandedKey].steps[this.currentStepKey]
      // console.log(step.selected)

      switch (stepKey) {
        case 'model':
          if (!step.selected) {
            this.vehicleSelectionError = 'Kein Model ausgewählt!'
            return
          }
          break
        case 'modelVariant':
          if (this.nextStepKey === null) {
            step.selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }
          break
        case 'driveType':
          if (!step.selected) {
            this.vehicleSelectionError = 'Kein Antrieb ausgewählt!'
            return
          } else {
            if (this.nextStepKey === null) {
              step.selected = true
              this.defineStepKey()
            } else {
              this.goForward()
            }
          }
          break
        // case 'rimColor':
        //   if (this.nextStepKey === null) {
        //     step.selected = true
        //     this.defineStepKey()
        //   } else {
        //     this.goForward()
        //   }
        //   break
        case 'carRegistration':
          if (!step.selectedOption.registration && step.selectedOption.mileage === 0) {
            this.vehicleSelectionError =
              'Erstzulassung darf nicht Leer sein! Kilometerstand darf nicht 0 sein! '
            // console.log(this.vehicleSelectionError)
            return
          } else if (!step.selectedOption.registration) {
            this.vehicleSelectionError = 'Erstzulassung darf nicht Leer sein!'
            // console.log(this.vehicleSelectionError)
            return
          } else if (step.selectedOption.mileage === 0) {
            this.vehicleSelectionError = 'Kilometerstand darf nicht 0 sein!'
            // console.log(this.vehicleSelectionError)
            return
          } else if (!step.selectedOption.availability) {
            if (!step.selectedOption.availableFrom) {
              // console.log(step.selectedOption.availableFrom)
              this.vehicleSelectionError = 'Verfügbarkeit darf nich leer sein'
              return
            }
            if (this.nextStepKey === null) {
              step.selected = true
              this.defineStepKey()
            } else {
              this.goForward()
            }
          } else {
            this.vehicleSelectionError = null
            if (this.nextStepKey === null) {
              step.selected = true
              this.defineStepKey()
            } else {
              this.goForward()
            }
          }

          break
        case 'autopilot':
          step.selected = true
          if (!this.accordion.accordion4.steps.rims.selected) {
            this.accordion.accordion4.steps.rims.selected = true
          }
          nextTick()
          if (this.nextStepKey === null) {
            this.defineStepKey()
          } else {
            this.goForward()
          }
          break
        case 'rims':
          step.selected = true
          if (!this.accordion.accordion4.steps.rimType.selected) {
            this.accordion.accordion4.steps.rimType.selected = true
          }
          if (this.nextStepKey === null) {
            this.defineStepKey()
          } else {
            this.goForward()
          }
          // console.log(this.accordion)

          break
        case 'rimType':
          if (this.nextStepKey === null) {
            step.selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }
          break
        case 'customEquipment':
          if (this.nextStepKey === null) {
            step.selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }

          break
        case 'damage':
          if (this.nextStepKey === null) {
            step.selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }
          break
        case 'accident':
          if (this.nextStepKey === null) {
            step.selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }
          break
        case 'price':
          if (this.nextStepKey === null) {
            step.selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }
          break
        case 'finance':
          if (this.nextStepKey === null) {
            step.selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }
          break

        default:
          break
      }

      // console.log('nextSelection expandedKey', this.currentExpandedKey)
      // console.log('nextSelection stepKey', this.currentStepKey)
    },
    selectOptions(option, stepKey) {
      this.vehicleSelectionError = null
      // console.log('selecteOption calld', this.currentExpandedKey, stepKey, option)

      if (!this.accordion[this.currentExpandedKey].steps[stepKey].selectedOption) {
        this.accordion[this.currentExpandedKey].steps[stepKey].selectedOption = {}
      }
      this.accordion[this.currentExpandedKey].steps[stepKey].selectedOption = option

      this.currentStepKey = stepKey
      switch (stepKey) {
        case 'model':
          this.vehicleSelectionError = null
          if (!this.accordion[this.currentExpandedKey].steps[stepKey].selected) {
            this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selected = true
            this.generateModelVariantOptions(option)
            this.defineStepKey()
            // console.log(this.currentStepKey)
          } else {
            // console.log('else')
            this.currentStepKey = stepKey
            this.generateModelVariantOptions(option)
            this.accordion[this.currentExpandedKey].steps.driveType.selectedOption = {}
            // console.log(this.accordion)
            // console.log(this.currentStepKey)
            return
          }
          break
        case 'modelVariant':
          this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selected = true
          if (option.value === 'Model3Performance') {
            this.accordion.accordion3.steps.rimColor.selected = false
            this.accordion.accordion3.steps.rimColor.selectedOption = {}
          } else {
            this.accordion.accordion3.steps.rimColor.selectedOption = {
              value: 'Standard',
              label: 'Standard'
            }
          }
          if (!this.accordion[this.currentExpandedKey].steps[stepKey].selected) {
            if (
              option.value === 'Model3StandardRange+' ||
              option.value === 'Model3Performance' ||
              option.value === 'ModelYStandardRange' ||
              option.value === 'ModelYPerformance'
            ) {
              this.accordion[this.currentExpandedKey].steps.driveType.selected = true
              this.accordion[this.currentExpandedKey].steps.driveType.selectedOption = {}
            }
            this.defineStepKey()
          } else {
            if (
              option.value === 'Model3StandardRange+' ||
              option.value === 'Model3Performance' ||
              option.value === 'ModelYStandardRange' ||
              option.value === 'ModelYPerformance'
            ) {
              this.accordion[this.currentExpandedKey].steps.driveType.selected = true
              this.accordion[this.currentExpandedKey].steps.driveType.selectedOption = {}
            } else {
              this.accordion[this.currentExpandedKey].steps.driveType.selected = false

              this.defineStepKey()
            }
            setTimeout(() => {
              this.generateRimTypeOptions()
            }, 0)
          }
          // console.log(this.accordion)
          // console.log(this.currentStepKey)
          break
        case 'driveType':
          this.vehicleSelectionError = null
          setTimeout(() => {
            this.accordion[this.currentExpandedKey].steps[stepKey].selected = true
          }, 0)
          // console.log(this.accordion)
          // console.log(this.currentStepKey)
          break
        case 'rims':
          if (!this.accordion[this.currentExpandedKey].steps[stepKey].selected) {
            this.accordion[this.currentExpandedKey].steps[stepKey].selected = true
            setTimeout(() => {
              this.generateRimTypeOptions()
            }, 0)
          } else {
            setTimeout(() => {
              this.generateRimTypeOptions()
            }, 0)
          }
          // console.log(this.accordion)
          // console.log(this.currentStepKey)
          break
        case 'rimType':
          this.accordion[this.currentExpandedKey].steps[stepKey].selected = true
          nextTick()
          // console.log(this.currentStepKey)

          break
        case 'bodyColor':
          setTimeout(() => {
            this.accordion.accordion3.steps.interiorColor.selectedOption = {}
          }, 0)

          if (this.nextStepKey === null) {
            this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selected = true
            this.defineStepKey()
          } else {
            this.goForward()
          }

          // console.log(this.accordion)
          // console.log(this.currentStepKey)
          break
        case 'interiorColor':
          if (
            this.accordion[this.currentExpandedKey].steps.rimColor.selectedOption.value ===
            'Standard'
          ) {
            this.accordion[this.currentExpandedKey].steps.rimColor.selected = true
          }

          if (this.nextStepKey === null) {
            this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selected = true

            this.defineStepKey()
          } else {
            this.goForward()
          }
          // console.log(this.accordion)
          // console.log(this.currentStepKey)
          break
        case 'rimColor':
          this.nextStepKey = null
          this.nextAccordionKey = null
          this.accordion[this.currentExpandedKey].steps[this.currentStepKey].selected = true
          this.defineStepKey()

          break
        // case 'autopilot':
        //   this.accordion[this.currentExpandedKey].steps[stepKey].selected = true
        //   break
        default:
          // console.log(`Unknown stepKey: ${stepKey}`)
          break
      }
    },
    selectMutlipleCheckboxes(option, stepKey) {
      const step = this.accordion[this.currentExpandedKey].steps[stepKey]
      let selectedIndex = null
      let selectedOptionPath = null
      if (stepKey === 'submit') {
        selectedIndex = step.selectedOption.marketing.indexOf(option)
        selectedOptionPath = step.selectedOption.marketing
      } else {
        selectedIndex = step.selectedOption.indexOf(option)
        selectedOptionPath = step.selectedOption
      }

      if (selectedIndex === -1) {
        // Add option to selectedOption array
        selectedOptionPath.push(option)
      } else {
        // Remove option from selectedOption array
        selectedOptionPath.splice(selectedIndex, 1)
      }
      // console.log('accordion', JSON.stringify(this.accordion, null, 2))
    },
    // console.log('accordion', JSON.stringify(this.accordion, null, 2))
    generateModelVariantOptions(model) {
      if (model.value === 'Model3') {
        this.accordion.accordion1.steps.modelVariant.options = [
          { value: 'Model3StandardRange+', label: 'Model 3 Standard Range +' },
          { value: 'Model3LongRange', label: 'Model 3 Long Range' },
          { value: 'Model3Performance', label: 'Model 3 Performance' }
        ]
        this.accordion.accordion4.steps.customEquipment.options = [
          { value: 'Highland', label: 'Ausführung Highland' },
          { value: 'TrailerHitch', label: 'Anhängerkupplung' },
          { value: 'CeramicSealing', label: 'Keramikversiegelung' },
          { value: 'AccBoost', label: 'ACC Boost' },
          { value: 'Homelink', label: 'Homelink' },
          { value: 'SecondTireSetOriginal', label: '2. Radsatz (komplett original Tesla' },
          { value: 'SecondTireSetThirdParty', label: '2. Radsatz (komplett Zubehör)' },
          { value: 'SecondTireSetTiresOnly', label: '2. Radsatz (nur Reifen)' }
        ]
        this.accordion.accordion1.steps.modelVariant.selectedOption = {
          value: 'Model3StandardRange+',
          label: 'Model 3 Standard Range +'
        }
        this.accordion.accordion3.steps.rimColor.selectedOption = {
          value: 'Standard',
          label: 'Standard'
        }
        nextTick(async () => {
          this.accordion.accordion1.steps.modelVariant.selected = true
          this.accordion.accordion1.steps.driveType.selected = true
        })
      } else if (model.value === 'ModelY') {
        this.accordion.accordion1.steps.modelVariant.options = [
          { value: 'ModelYStandardRange', label: 'Model Y Standard Range' },
          { value: 'ModelYLongRange', label: 'Model Y Long Range' },
          { value: 'ModelYPerformance', label: 'Model Y Performance' }
        ]
        this.accordion.accordion4.steps.customEquipment.options = [
          { value: 'TrailerHitch', label: 'Anhängerkupplung' },
          { value: 'CeramicSealing', label: 'Keramikversiegelung' },
          { value: 'AccBoost', label: 'ACC Boost' },
          { value: 'Homelink', label: 'Homelink' },
          { value: 'SecondTireSetOriginal', label: '2. Radsatz (komplett original Tesla' },
          { value: 'SecondTireSetThirdParty', label: '2. Radsatz (komplett Zubehör)' },
          { value: 'SecondTireSetTiresOnly', label: '2. Radsatz (nur Reifen)' }
        ]
        this.accordion.accordion1.steps.modelVariant.selectedOption = {
          value: 'ModelYStandardRange',
          label: 'Model Y Standard Range'
        }
        this.accordion.accordion3.steps.rimColor.selectedOption = {
          value: 'Standard',
          label: 'Standard'
        }
        nextTick(async () => {
          this.accordion.accordion1.steps.modelVariant.selected = true
          this.accordion.accordion1.steps.driveType.selected = true
        })
      } else {
        this.accordion.accordion4.steps.customEquipment.options = []
        this.accordion.accordion1.steps.modelVariant.options = []
      }
      setTimeout(() => {
        this.generateRimTypeOptions()
      }, 0)
    },
    generateRimTypeOptions() {
      let rims = this.accordion.accordion4.steps.rims.selectedOption
      // console.log(rims)
      let model = this.accordion.accordion1.steps.model.selectedOption
      // console.log(model)
      let modelVariant = this.accordion.accordion1.steps.modelVariant.selectedOption
      // console.log(modelVariant)
      console.log(rims.value)
      console.log(model.value)
      console.log(modelVariant.value)

      if (rims.value === 'OriginalRims') {
        // console.log(model)

        if (model.value === 'Model3') {
          if (modelVariant.value === 'Model3Performance') {
            this.accordion.accordion4.steps.rimType.options = [
              { value: 'Model3PerformanceRims', label: '20" Felgen (Performance)' }
            ]
            console.log(this.accordion.accordion4.steps.rimType.options)
            this.accordion.accordion4.steps.rimType.selectedOption = {
              value: 'Model3PerformanceRims',
              label: '20" Felgen (Performance)'
            }
            console.log(this.accordion.accordion4.steps.rimType.selectedOption)
          } else {
            this.accordion.accordion4.steps.rimType.options = [
              { value: 'StandardOriginalRims', label: '18" Aero Felgen' },
              { value: 'SportOriginalRims', label: '19" Sport-felgen' }
            ]
            this.accordion.accordion4.steps.rimType.selectedOption = {
              value: 'StandardOriginalRims',
              label: '18" Aero Felgen'
            }
          }
        }
        if (model.value === 'ModelY') {
          if (modelVariant.value === 'ModelYPerformance') {
            this.accordion.accordion4.steps.rimType.options = [
              { value: 'ModelYPerformanceRims', label: '21" Überturbine' }
            ]
            console.log(this.accordion.accordion4.steps.rimType.selectedOption)
            this.accordion.accordion4.steps.rimType.selectedOption = {
              value: 'ModelYPerformanceRims',
              label: '21" Überturbine'
            }
            console.log(this.accordion.accordion4.steps.rimType.selectedOption)
            return
          } else {
            console.log('hello')

            this.accordion.accordion4.steps.rimType.options = [
              { value: 'GeminiRims', label: '19" Gemini' },
              { value: 'InductionRims', label: '20" Induction' }
            ]
          }
          this.accordion.accordion4.steps.rimType.selectedOption = {
            value: 'GeminiRims',
            label: '19" Gemini'
          }
        }
      } else if (rims.value === 'AccessoryRims') {
        if (model.value === 'Model3') {
          this.accordion.accordion4.steps.rimType.options = [
            { value: 'EighteenInchRims', label: '18" Felgen' },
            { value: 'NineteenInchRims', label: '19" Felgen' },
            { value: 'TwentyInchRims', label: '20" Felgen' }
          ]
          this.accordion.accordion4.steps.rimType.selectedOption = {
            value: 'EighteenInchRims',
            label: '18" Felgen'
          }
        } else {
          this.accordion.accordion4.steps.rimType.options = [
            { value: 'NineteenInchRims', label: '19" Felgen' },
            { value: 'TwentyInchRims', label: '20" Felgen' },
            { value: 'TwentyOneInchRims', label: '21" Felgen' }
          ]
          this.accordion.accordion4.steps.rimType.selectedOption = {
            value: 'NineteenInchRims',
            label: '19" Felgen'
          }
        }
      }
      console.log(this.accordion.accordion4.steps)
    },
    displaySelected(content) {
      const accordion = this.accordion

      switch (content) {
        case 'model':
          const modelDisplays = Object.values(accordion.accordion1.steps)
            .map((step) => {
              if (
                step.title !== 'Modell' &&
                step.selectedOption &&
                step.selectedOption.value &&
                step.selectedOption.label
              ) {
                return step.selectedOption.label
              }
            })
            .filter(Boolean)
          return modelDisplays.length > 0 ? modelDisplays.join(', ') : 'Kein Modell ausgewählt'
        case 'carRegistration':
          const carRegistration = accordion.accordion2.steps.carRegistration
          if (carRegistration.selectedOption) {
            const { registration, mileage } = carRegistration.selectedOption
            if (registration && mileage) {
              const [year, month, day] = registration.split('-')
              const formattedErstzulassung = `${day}. ${this.monthNames[month - 1]} ${year}`
              return `Erstzulassung: ${formattedErstzulassung}, Kilometerstand: ${mileage}`
            } else if (registration) {
              const [year, month, day] = registration.split('-')
              const formattedErstzulassung = `${day}. ${this.monthNames[month - 1]} ${year}`

              return `Erstzulassung: ${formattedErstzulassung}`
            } else if (mileage) {
              return `Kilometerstand: ${mileage}`
            }
          }
          return 'Erstzulassung, Kilometerstand: 0'
        case 'colors':
          const bodyColor = accordion.accordion3.steps.bodyColor.selectedOption
          const interiorColor = accordion.accordion3.steps.interiorColor.selectedOption
          const rimColor = accordion.accordion3.steps.rimColor.selectedOption
          let colorDisplays = []

          if (bodyColor && bodyColor.value && bodyColor.label) {
            colorDisplays.push(`Außen: ${bodyColor.label}`)
          }

          if (interiorColor && interiorColor.value && interiorColor.label) {
            colorDisplays.push(`Innen: ${interiorColor.label}`)
          }
          if (rimColor && rimColor.value && rimColor.label) {
            colorDisplays.push(`Felgen: ${rimColor.label}`)
          }

          return colorDisplays.length > 0 ? colorDisplays.join(', ') : accordion.accordion3.title
        case 'equipment':
          const autopilot = accordion.accordion4.steps.autopilot.selectedOption.label
          const rims = accordion.accordion4.steps.rims.selectedOption.label
          const rimType = accordion.accordion4.steps.rimType.selectedOption.label
          const customEquipment = Object.keys(
            accordion.accordion4.steps.customEquipment.selectedOption || {}
          ).length
            ? `${Object.keys(accordion.accordion4.steps.customEquipment.selectedOption || {}).length} zusätz. Ausstattungen`
            : '0 Ausgewählt'

          const equipmentDisplays = [
            autopilot && `${autopilot}`,
            rimType && `${rimType}`,
            customEquipment
          ].filter(Boolean)

          return equipmentDisplays.length > 0
            ? equipmentDisplays.join(', ')
            : accordion.accordion3.title

        case 'damage':
          const accident = accordion.accordion5.steps.accident.selectedOption.accident
          const damage = accordion.accordion5.steps.damage.selectedOption.damage

          if (accident && damage) {
            return 'Unfallfahrzeug, Beschädigt'
          } else if (accident && !damage) {
            return 'Unfallfahrzeug, ohne Beschädigungen'
          } else if (!accident && damage) {
            return 'Unfallfrei, beschädigt'
          } else {
            return 'Unfallfrei, ohne Beschädigungen'
          }
        case 'priceRequest':
          return `${!accordion.accordion6.steps.price.selectedOption.saleType || accordion.accordion6.steps.price.selectedOption.saleType === 'Privater Verkauf' ? 'Verkauf von Privat' : 'gewerblicher Verkauf'}, ${!accordion.accordion6.steps.price.selectedOption.priceRequest ? 'Preisvorschlag' : `Preisvorschlag: ${accordion.accordion6.steps.price.selectedOption.priceRequest} €`}`

        case 'submit':
          return 'Erhalten Sie noch heute Ihr individuelles Angebot'
        default:
          return ''
      }
    },
    transformAccordionToFormData() {
      const accordion = this.accordion
      const requestData = {
        model: accordion.accordion1.steps.modelVariant.selectedOption.label,
        registration: accordion.accordion2.steps.carRegistration.selectedOption.registration,
        mileage: accordion.accordion2.steps.carRegistration.selectedOption.mileage,
        bodyColor: accordion.accordion3.steps.bodyColor.selectedOption.label,
        interiorColor: accordion.accordion3.steps.interiorColor.selectedOption.label,
        rimColor: accordion.accordion3.steps.rimColor.selectedOption.label,
        rims: `${accordion.accordion4.steps.rims.selectedOption.label}, ${accordion.accordion4.steps.rimType.selectedOption.label}`,
        autopilot: accordion.accordion4.steps.autopilot.selectedOption.label,
        price: accordion.accordion6.steps.price.selectedOption.priceRequest,
        firstName: accordion.accordion7.steps.submit.selectedOption.firstName,
        lastName: accordion.accordion7.steps.submit.selectedOption.lastName,
        email: accordion.accordion7.steps.submit.selectedOption.email,
        phone: accordion.accordion7.steps.submit.selectedOption.phone,
        message: accordion.accordion7.steps.submit.selectedOption.message || ''
      }
      requestData.driveType =
        accordion.accordion1.steps.modelVariant.selectedOption.value === 'Model3LongRange'
          ? accordion.accordion1.steps.driveType.selectedOption.label
          : null
      requestData.availableFrom = accordion.accordion2.steps.carRegistration.selectedOption
        .availability
        ? null
        : accordion.accordion2.steps.carRegistration.selectedOption.availableFrom

      requestData.accident = accordion.accordion5.steps.accident.selectedOption.accident
        ? accordion.accordion5.steps.accident.selectedOption.accidentDescription
        : null
      requestData.damages = accordion.accordion5.steps.damage.selectedOption.damage
        ? accordion.accordion5.steps.damage.selectedOption.damageDescription
        : null
      requestData.saleType =
        accordion.accordion6.steps.price.selectedOption.saleType === 'Privater Verkauf'
          ? accordion.accordion6.steps.price.selectedOption.saleType
          : accordion.accordion6.steps.price.selectedOption.mwSt
            ? 'Gewerblicher Verkauf (MwSt. ausweisbar)'
            : 'Gewerblicher Verkauf (MwSt. nicht ausweisbar)'
      requestData.purchaseType = !accordion.accordion6.steps.finance.selectedOption.financing
        ? 'Keine Finanzierung'
        : accordion.accordion6.steps.finance.selectedOption.vehicleLetterAtBank
          ? 'Finanzierung, KFZ-Brief liegt bei der Bank'
          : 'Finanzierung, KFZ-Brief liegt bei mir'

      const customEquipmentSelectedOption =
        accordion.accordion4.steps.customEquipment.selectedOption
      const customEquipmentValue = customEquipmentSelectedOption.find(
        (option) => option.label === 'custom-equipment'
      )

      const customEquipmentLabels = customEquipmentSelectedOption
        .filter((option) => option.label !== 'custom-equipment')
        .map((option) => option.label)

      if (customEquipmentValue && customEquipmentValue.value !== '') {
        requestData.customEquipment = customEquipmentValue.value
      } else {
        requestData.customEquipment = ''
      }

      requestData.equipment = customEquipmentLabels
      requestData.marketing = accordion.accordion7.steps.submit.selectedOption.marketing.map(
        (option) => option.label
      )
      this.requestData = requestData // <--- Set the requestData state

      //console.log('Request data:', JSON.stringify(this.requestData, null, 2))

      return requestData
    },
    async sendRequest() {
      try {
        this.transformAccordionToFormData()
        this.loading = true

        const url = `https://fn.cloud.audaris.icu/2129/pre-tradein-widget`
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.requestData)
        })
        //console.log('this is hte response', response)
        if (!response.ok) {
          this.handleFetchError(response.status)
          return
        }
        //console.log(response.status)

        if (response.status === 200) {
          this.responseDataCheck = true
        }
      } catch (error) {
        console.error('Fehler beim Fetchen der Daten:', error)
      } finally {
        this.loading = false
        //console.log('After setting loading to false:', this.loading)
      }
    },

    handleFetchError(status) {
      switch (status) {
        case 429:
          this.requestError = 'Zu viele Anfragen, bitte versuche es später noch einmal'
          break
        default:
          this.requestError = `Fehler ${status}: ${this.getErrorMessage(status)}`
      }
    },

    getErrorMessage(status) {
      switch (status) {
        case 400:
          return 'Ungültige Anfrage'
        case 401:
          return 'Authentifizierung fehlgeschlagen'
        case 500:
          return 'Interner Serverfehler'
        default:
          return 'Unbekannter Fehler'
      }
    }
  }
})
